<template>
  <div :class="'signal ' + uniqueClass" v-on:click="open({ data, key_ })">
    <div class="content">
      <div class="top">
        <span class="logo">
          <ion-thumbnail>
            <div
              :style="
                'background-image: url(' +
                server +
                '/storage/tokens/' +
                data.logo +
                ')'
              "
            ></div>
          </ion-thumbnail>
        </span>

        <span class="left">
          <div class="top">
            <strong class="market">{{ data.market }}</strong>
            <span class="date">{{
              $filters.signalTimeLT(data.created_at, momentJsLocale)
            }}</span>
          </div>

          <div class="bottom">
            <span :class="'translate badge outline ' + (data.leverage_type == 'long' ? 'success' : 'danger')" v-if="data.type != 'spots'">
              {{ leverage }}
            </span>
            <span class="translate badge success action-price buy-price"
              >{{ lang.entry ?? "Entry" }} {{ data.buy }}</span
            >
            <span class="translate badge danger action-price sell-price">
              {{ lang?.stop ?? "Stop" }} {{ data.stoploss }}
            </span>
          </div>
        </span>
      </div>

      <div
        class="prices"
        :style="'height: ' + pricesHeight + 'px'"
        @click.prevent.stop="togglePrices()"
      >
        <div class="price current">
          <span class="title"> {{  lang.current_price ?? "Current price"  }} </span>

          <!-- Current price value -->
          <span v-if="extra && extra.tickerPrice">{{ extra.tickerPrice }}</span>

          <ion-skeleton-text
            v-else
            animated
            style="width: 60px;"
          ></ion-skeleton-text>

          <span>

            <span v-if="extra && extra.tickerPriceChange" :class="'percent ' + profitMood(extra.tickerPriceChange)">
              {{ profit(extra.tickerPriceChange) }}

              <ion-icon
                v-if="extra.tickerPriceChange > 0"
                :icon="arrowUpSharp"
              ></ion-icon>
              <ion-icon
                v-if="extra.tickerPriceChange < 0"
                :icon="arrowDownSharp"
              ></ion-icon>
            </span>

            &nbsp;
            <span class="toggle">
              <ion-icon :icon="chevronUp" v-if="targetsOpen"></ion-icon>
              <ion-icon :icon="chevronDown" v-if="!targetsOpen"></ion-icon>
            </span>
          </span>
        </div>

        <template v-for="(target, key) in targets" :key="key">
          <div
            :class="{
              price: true,
              target: true,
              achieved: target.achieved,
            }"
          >
            <span class="title">
              {{
                lang?.["target_" + (key + 1)] ??
                "Target " + (key + 1)
              }}
              <ion-icon
                :icon="checkmarkSharp"
                v-if="target.achieved"
              ></ion-icon>
            </span>
            <span class="value"> {{ target.value }} </span>
            <span :class="'percent'"> {{ target.percent }}% </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonThumbnail,
  // IonText,
  IonSkeletonText,
  IonIcon,
} from "@ionic/vue";
import {
  arrowDownSharp,
  arrowUpSharp,
  chevronDown,
  chevronUp,
  checkmarkSharp,
} from "ionicons/icons";

export default {
  inject: ["store"],
  props: ["data", "key_", "readLabel", "ready", "extra", "uniqueClass"],
  emits: ["read"],
  components: {
    IonThumbnail,
    // IonText,
    IonSkeletonText,
    IonIcon,
  },

  data() {
    return {
      ticker_price: null,
      ticker_24hr: null,
      arrowDownSharp,
      arrowUpSharp,
      chevronDown,
      chevronUp,
      checkmarkSharp,
      targetsOpen: false,
    };
  },

  computed: {
    server() {
      return this.store.server;
    },

    pricesHeight() {
      if (this.targetsOpen) {
        return 40 + 39 * this.targets.length;
      } else {
        return 40;
      }
    },

    targets() {
      return JSON.parse(this.data.targets);
    },

    leverage() {
      let leverageType = this.data.leverage_type.charAt(0).toUpperCase() + this.data.leverage_type.slice(1)

      return leverageType + " " +  this.data.leverage + "X"
    },
  },

  methods: {
    togglePrices() {
      this.targetsOpen = !this.targetsOpen;
    },

    open(data) {
      this.store.setters.signalInView(data);
      this.$router.push("/signal");
    },

    profit(profit) {
      return profit > 0 ? "+" + profit : profit;
    },

    profitMood(profit) {
      let profit_ = Number(profit);

      if (profit_ > 0) {
        return "success-text";
      } else if (profit_ == 0) {
        return "warning-text";
      } else {
        return "danger-text";
      }
    },
  },

  mounted() {
    // console.log({
    //   key: this.key_
    // })
  },
};
</script>

<style scoped lang="scss">
.signal {
  background: #fff;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin-bottom: 12px;
  //   box-shadow: 0 7px 51px -49px #aaa;
  border-radius: 20px 0px 20px 20px;
  // border-top-left-radius: 30px;
  // border-top-right-radius: 0px;
  position: relative;
  border: 1px solid #f0f0f0;
  // animation: slideUp .3s forwards;

  &.hidden {
    display: none;
  }

  .content {
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    // height: 110px;

    > .top {
      width: 100%;
      display: flex;
      // background: #ccc;

      .logo {
        margin-right: 10px;
        border-radius: 20px;

        ion-thumbnail {
          --size: 50px;
          --border-radius: 20px;

          > div {
            width: 50px;
            height: 50px;
            background-size: cover;
            border-radius: 20px;
          }
        }
      }

      > .left {
        flex-grow: 1;

        > .top {
          display: flex;
          justify-content: space-between;

          .market {
            color: #000;
            font-size: calc(16px + $extra-font-size);
          }

          .date {
            color: #848298;
            font-weight: 600;
            font-size: calc(12px + $extra-font-size);
          }
        }

        > .bottom {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          // margin-top: 10px;

          .badge {
            margin-top: 10px;
            margin-right: 10px;
          }
        }
      }
    }

    > .prices {
      width: 100%;
      transition: height 0.3s ease;
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid #f0f0f0;
      background-color: #{lighten($primary-color, 88%)};
      margin-top: 10px;
      // background: #999;

      .price {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-size: calc(14px + $extra-font-size);
        font-weight: 600;
        border-top: 1px solid #{lighten($tetiary-color, 39%)};

        &.current {
          border: none;
          color: #{darken($tetiary-color, 20%)};
        }

        .title {
          font-size: calc(12px + $extra-font-size);
          font-weight: normal;
        }

        .toggle {
          font-size: calc(16px + $extra-font-size);
          color: #444;
        }
      }

      .target {
        color: $tetiary-color;

        &.achieved {
          background: $success-color;
          // background: #{darken($success-color, 10%)};
          color: #fff;
          border-color: #{darken($success-color, 5%)};
        }
      }
    }

    .left-descr {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      flex-grow: 1;
      text-align: left;

      .exchange {
        color: #848298;
        font-weight: 500;
        font-size: calc(16px + $extra-font-size);
      }

      .market {
        color: #000;
        font-size: calc(16px + $extra-font-size);
      }

      .action-price {
        font-size: calc(12px + $extra-font-size);
        width: fit-content;
        font-weight: 500;

        &.buy-price {
          color: #2dd36f;
        }

        &.sell-price {
          color: #eb445a;
        }

        &.risk {
          padding: 2px 5px;
          // border: 1px solid;
          border-radius: 3px;
          margin-top: 3px;
          font-size: calc(10px + $extra-font-size);

          &.danger {
            // color: #{lighten($danger-color, 0%)};
            color: #{darken($danger-color, 20%)};
            background: #{lighten($danger-color, 34%)};
          }

          &.warning {
            // color: #{lighten($warning-color, 0%)};
            // color: #000;
            color: #{darken($warning-color, 20%)};
            background: #{lighten($warning-color, 40%)};
          }

          &.success {
            // color: #{lighten($success-color, 0%)};
            color: #{darken($success-color, 20%)};
            background: #{lighten($success-color, 40%)};
          }
        }
      }
    }

    .right-descr {
      text-align: right;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .profit-info {
        .last-price {
          color: #000;
          font-size: calc(16px + $extra-font-size);
        }

        .current-profit {
          font-weight: normal;
          font-size: calc(16px + $extra-font-size);
        }
      }

      .date {
        color: #848298;
        font-weight: 500;
        font-size: calc(14px + $extra-font-size);
        padding-top: 10px;

        &.new {
          color: green;
          font-weight: 700;
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    top: 20px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

//Global styles
.badge {
  font-size: calc(12px + $extra-font-size);
  min-width: fit-content;
  font-weight: 500;
  padding: 5px;
  border-radius: 3px;
  // font-size: calc(10px + $extra-font-size);

  @media (max-width: 380px) {
    font-size: calc(10px + $extra-font-size);
  }

  &.danger {
    background: #{darken($danger-color, 5%)};
    color: #fff;

    &.outline {
      background: transparent;
      color: #{darken($danger-color, 5%)};
      border: 1px solid #{darken($danger-color, 5%)};
    }
  }

  &.warning {
    background: $warning-color;
    color: #fff;

    &.outline {
      background: transparent;
      color: $warning-color;
      border: 1px solid $warning-color;
    }
  }

  &.success {
    background: #{darken($success-color, 5%)};
    color: #fff;

    &.outline {
      background: transparent;
      color: #{darken($success-color, 5%)};
      border: 1px solid #{darken($success-color, 5%)};
    }
  }

  &.tetiary {
    background: #{darken($tetiary-color, 5%)};
    color: #fff;

    &.outline {
      background: transparent;
      color: #{darken($tetiary-color, 5%)};
      border: 1px solid #{darken($tetiary-color, 5%)};
    }
  }
}

.success-text {
  color: $success-color;
}

.danger-text {
  color: $danger-color;
}

.warning-text {
  color: $warning-color;
}

// .secondary-text {
//   color: #555;
// }
</style>