<template>
  <infinite-scroll-recycler
    :action="action"
    :size="size"
    :loadData="loadData"
    :itemMargin="itemMargin"
    :bgColor="bgColor"
    :extras="tickerInfos"
    :itemUniqueClass="signalItemUniqueClass"
    ref="recycler"
  >
    <!-- Mininghamster's version -->
    <template v-slot:item="props">
      <signal-item
        :data="props.data"
        :key_="props.key_"
        :readLabel="readLabel"
        :extra="props.extra"
        :uniqueClass="signalItemUniqueClass"
        @read="props.read"
      ></signal-item>
    </template>

    <template v-slot:tombstone="props">
      <tombstone :loading="props.loading"></tombstone>
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </infinite-scroll-recycler>
</template>

<script>
// import {defineAsyncComponent} from "vue";
import InfiniteScrollRecycler from "./InfiniteScrollRecycler.vue";
import Tombstone from "./Tombstone";
// const Tombstone = defineAsyncComponent(() => import("./Tombstone"));
import SignalItem from "./SignalItem";
// const SignalItem = defineAsyncComponent(() => import("./SignalItem"));

export default {
  props: [
    "loadData",
    "action",
    // "actionHandler",
    // "actionTrigger",
    "readLabel",
    "signalItemUniqueClass",
  ],
  inject: ["store"],
  components: {
    InfiniteScrollRecycler,
    Tombstone,
    SignalItem,
  },

  data() {
    return {
      size: 80, //Whole even number
      itemMargin: 12,
      bgColor: "#fafafc",
      isMounted: false,
      tickerInfos: null,
      timer: null,
      cancelSource: null,
    };
  },

  computed: {
    signalsInView() {
      if (this.isMounted) {
        return this.$refs.recycler.itemsInView;
      } else {
        return [];
      }
    },
  },

  watch: {
    signalsInView() {
      let self = this;
      self.tickerInfos = null;

      if (self.cancelSource) {
        self.cancelSource.cancel();
      }

      // self.updatePrices();

      if (self.timer) {
        clearTimeout(self.timer);
      }

      self.timer = setTimeout(() => {
        self.timer = null;
        self.updatePrices();
      }, 500);
    },
  },

  methods: {
    async updatePrices() {
      let self = this;
      self.cancelSource = window.axios.CancelToken.source();
      if (this.signalsInView.length > 0) {
        try {
          var resp = await window.axios({
            url: self.store.endpoint + "/ticker-info",
            method: "post",
            data: {
              signals: self.signalsInView,
            },
            cancelToken: self.cancelSource.token,
          });
        } catch (err) {
          err;
        }

        if (resp && resp.status == 200) {
          let a = resp.data;
          if (a[0].id == self.signalsInView[0].id) {
            self.tickerInfos = a;
          }
        }

        self.cancelSource = null;
      }
    },

    updateOnEnter() {
      let self = this;

      if (self.cancelSource) {
        self.cancelSource.cancel();
      }

      if (self.timer) {
        clearTimeout(self.timer);
      }

      self.timer = setTimeout(() => {
        self.timer = null;
        self.updatePrices();
      }, 500);
    },

    init() {
      this.$refs.recycler.init();
    }
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
</style>