<template>
  <signal-list
    :loadData="loadData"
    :actionHandler="listActionHandler"
    :actionTrigger="listActionTrigger"
    :signalItemUniqueClass="itemName + '-' + listName"
    :parentElementClass="parentElementClass"
    :name="listName"
    ref="List"
    @scrolledToTop="listScrolledToTop"
  >
    <template v-slot:no-result>
      <no-result
        :title="noResultTitle"
        :description="noResultDescription"
        :actionText="noResultActionText"
        :clickHandler="noResultClickHandler"
        :alert="noResultAlert"
      >
        <template v-slot:image>
          <img :src="noResultImage" srcset="" />
        </template>
      </no-result>
    </template>

    <template v-slot:network-error>
      <network-error :clickHandler="hardReset"></network-error>
    </template>
  </signal-list>
</template>
  
  <script>
import SignalList from "@/views/components/cat-3/SignalList";
import NoResult from "@/views/components/cat-3/NoResult.vue";
import NetworkError from "@/views/components/cat-3/NetworkError.vue";

export default {
  emits: ["interfaces"],
  props: {
    itemName: {
      type: String,
    },

    listName: {
      type: String,
    },

    parentElementClass: {
      type: String,
    },

    loadUrl: {
      type: String,
    },

    //Prop from parent to know when there is an update in parent
    //to react to events like scroll to top, etc.
    updateProp: {
      type: Boolean,
    },

    //Passed to the server in the loadData() function. Determines wether signal items will have the unwatch button
    withUnwatchButton: {
      type: Boolean,
      default: false,
    },

    //Title text for no result component
    noResultTitle: {
      type: String,
    },

    //Description text for no result component
    noResultDescription: {
      type: String,
    },

    
    //Action text for no result component
    noResultActionText: {
      type: String,
    },

    //Image url for no result component
    noResultImage: {
      type: String
    },

    //Optional alert message in no result component
    noResultAlert: {
      default: null
    },

    //Click handler for no result compoment
    noResultClickHandler: {
      type: Function
    }
  },
  inject: ["store"],
  components: {
    SignalList,
    NoResult,
    NetworkError,
  },

  data() {
    return {
      created: false, //Flag for wether this component has been created

      listActionTrigger: false, //A boolean watched by the scroll recycler. The scroll recycler is a parent or some super parent of this component. Changes in this boolean trigger the ActionHandler function below which will be passed to the recycler as well.

      listActionHandler: null, //The ActionHandler called when the above trigger goes of in the scroll recycler.
    };
  },

  computed: {},

  methods: {

    //Function to run when list is scrolled to top
    listScrolledToTop() {
      let self = this;

      //If we have an update in this list
      if (self.updateProp) {
        //set the list action handler to the following event
        self.listActionHandler = (list) => {
          //the following event

          //Call the softReset() function of the recycler
          list.softReset();

          //Tell parent element to turn off update reactions as we've accounted for it.
          self.$emit("updateToggle", false);
        };

        //Trigger the event to which we set the list action handler.
        self.triggerListAction();
      }
    },

    //React to a list update
    update() {
      let self = this;

      //If scrolltop is 0, do a soft reset, else just set tell parent to alert user of update
      self.listActionHandler = (list) => {
        let scroller = document.getElementsByClassName(
          list.parentElementClass
        )[0];

        if (scroller.scrollTop == 0) {
          list.softReset();
        } else {
          //We're scrolled away from first item. Let parent item know there is an update to this list
          self.$emit("updateToggle", true);
        }
      };
      self.triggerListAction();
    },

    //Triggers the list action handler of the recycler, by toggling it's listActionTrigger prop which it watches.
    triggerListAction() {
      this.listActionTrigger = !this.listActionTrigger;
    },

    loadData(start, size) {
      let self = this;

      let data = {
        start: start,
        slice: size,
        device_id: self.store.state.deviceID,
        withUnwatchButton: self.withUnwatchButton,
      }; data;

      return window.axios({
        url: self.store.endpoint + self.loadUrl,
        method: "post",
        data: data,
      });
    },

    init() {
      //Initialize this list whenever we enter.
      let self = this;

      //If this list was just created
      if (!this.created) {
        //Initialize the list
        this.$refs.List.init();
        this.created = true;

        //Stop list from reloading when we leave and come back to this list, if it was already set to do so somewhere.
        this.store.setters.reloadOnEnter(self.listName, false);

        //If we've entered before.
      } else {
        //If the list has been set to reload by some other file because of an update it detected
        if (this.store.state.reloadOnEnter?.[self.listName]) {
          //Hard reset the list to display the update
          this.hardReset();

          //Let parent component know list update has been accounted for.
          //This will turn off update for all concerned compoments as well
          self.$emit("updateToggle", false);

          //Stop list from reloading when we leave and reenter it, since we've already accounted for the update.
          this.store.setters.reloadOnEnter(self.listName, false);
        } else {
          //No reloading happening, so we have to update item prices.

          //Update the prices of items in signalList.
          this.$refs.List?.updateOnEnter();
        }
      }
    },

    //Reset the list without loading. This function is manually triggered from parent components ONLY
    softReset() {
      let self = this;

      //Let parent component know list update has been accounted for
      self.$emit("updateToggle", false);

      //Trigger soft reset in the recycler
      self.listActionHandler = (self) => {
        self.softReset();
      };
      self.triggerListAction();
    },

    //Resets the list by reloading it.
    hardReset() {
      //Trigger hard reset in the recycler.
      this.listActionHandler = (self) => {
        self.hardReset();
      };
      this.triggerListAction();
    },

    emitInterface() {
      let self = this;

      //Emit the methods we would like to call from the parent, to the parent
      self.$emit("interfaces", {
        update: () => {
          self.update()
        },

        softReset: () => {
          self.softReset()
        },

        hardReset: () => {
          self.hardReset()
        }

      });
    },
  },
  mounted() {
    this.isMounted = true;

    //Emits on mount
    this.emitInterface();
  },
};
</script>
  
  <style scoped>
</style>