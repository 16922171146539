<template>
  <div class="tombstone">
    <div class="content">
      <span class="logo">
        <ion-thumbnail> </ion-thumbnail>
      </span>
      <span class="left-descr">
        <span class="exchange">
          <ion-skeleton-text
            :animated="loading"
            style="width: 50px"
          ></ion-skeleton-text>
        </span>
        <strong>
          <ion-skeleton-text
            :animated="loading"
            style="width: 100px"
          ></ion-skeleton-text>
        </strong>
        <span>
          <ion-skeleton-text
            :animated="loading"
            style="width: 100px"
          ></ion-skeleton-text>
        </span>
      </span>
      <span class="right-descr">
        <span class="profit-info">
          <ion-text class="last-price">
            <ion-skeleton-text
              :animated="loading"
              style="width: 60px; margin-left: auto"
            ></ion-skeleton-text>
          </ion-text>
          <ion-text class="current-profit" color="success">
            <ion-skeleton-text
              :animated="loading"
              style="width: 20px; margin-left: auto"
            ></ion-skeleton-text>
          </ion-text>
        </span>
        <span class="date">
          <ion-skeleton-text
            :animated="loading"
            style="width: 50px; margin-left: auto"
          ></ion-skeleton-text>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { IonThumbnail, IonText, IonSkeletonText } from "@ionic/vue";

export default {
  props: {
    loading: {
      type: Boolean,
    },
  },

  components: {
    IonThumbnail,
    IonText,
    IonSkeletonText,
  },

  data() {
    return {
      // loading: false
    };
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
.tombstone {
  background: #fff;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin-bottom: 20px;
  //   box-shadow: 0 7px 51px -49px #aaa;
  border-radius: 20px 0px 20px 20px;

  .content {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    height: 110px;

    .logo {
      flex: 0 1 40px;
      background: #f8f8f8;
      padding: 10px;
      border-radius: 20px;

      ion-thumbnail {
        --size: 30px;
        --border-radius: 20px;
      }
    }

    .left-descr {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      flex-grow: 1;
    }

    .right-descr {
      text-align: right;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .date {
        font-size: calc(14px + $extra-font-size);
        padding-top: 10px;
      }
    }
  }
}

ion-skeleton-text {
  border-radius: 1px;
}
</style>